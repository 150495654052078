<template>
  <li>
    <div class="flex items-center p-0.5 pl-1 rounded cursor-pointer hover:bg-gray-200">
      <button
        type="button"
        class="inline-flex items-center p-1 text-gray-400 border border-transparent rounded hover:bg-gray-300 focus:outline-none"
      >
        <fa-icon
          icon="chevron-right"
          class="fa-fw"
          size="xs"
        />
      </button>
      <fa-icon
        icon="folder"
        class="mr-2 text-blue-400 fa-fw"
      />
      <div class="text-sm leading-6 text-gray-500">
        {{ asset.name }}
      </div>
    </div>
  </li>

  <!-- <div
        v-on:dblclick="onSelect"
        v-on:click.prevent.stop="onToggleMultiselect"
        v-bind:draggable="canDrag"
        v-on:dragend="onDragEnd"
        v-on:dragstart="onDragStart"
        v-on:dragover="onDragOver"
        v-on:dragleave="onDragLeave"
        v-on:mouseover="onMouseOver"
        v-on:mouseleave="onMouseLeave"
        class="flex items-center text-sm text-gray-600 border-b border-gray-300 cursor-pointer select-none dark:border-gray-800 content-row dark:text-gray-100 hover:bg-blue-100 dark:hover:bg-gray-700"
        :class="[{ 'bg-gray-300 dark:bg-gray-700': isFocused, 'bg-blue-100 border-blue-200 text-blue-600': isMultiselected }, justifyClass]"
    >
        <div
            v-on:click.prevent
            class="flex items-center px-4 py-3 text-xs truncate cursor md:text-sm"
        >
            <AssetItemThumb v-bind:asset="asset" />

            <div
                v-on:click.prevent.stop="onSelect('edit')"
                class="mx-2 border-b border-gray-600 border-dashed cursor-pointer hover:border-solid"
                v-if="isEditable"
            >Edit</div>
        </div>
        <div class="hidden w-1/12 p-1 text-xs capitalize md:text-sm sm:flex" v-if="showComments">
            <span
                class="rounded-full btn btn-xs btn-indigo px-1 py-0.5"
                v-if="asset.comment_count > 0"
            >{{ asset.comment_count }}</span>
            <span class="px-1 py-0.5 text-gray-400" v-else>-</span>
        </div>
        <div
            class="hidden w-1/12 p-1 text-xs capitalize md:text-sm sm:flex"
            v-if="showType"
        >{{ asset.type }}</div>
        <div class="hidden w-1/12 p-1 text-xs md:text-sm sm:flex" v-if="showUploader">
            <template v-if="assetOwner">{{ assetOwner.name }}</template>
        </div>
        <div class="hidden w-1/12 p-1 text-xs md:text-sm sm:flex" v-if="showModified">
            <template v-if="asset.updated_at">{{ asset.updated_at | relative }}</template>
        </div>
        <div class="hidden w-1/12 p-1 text-xs md:text-sm sm:flex" v-if="showSize">
            <template v-if="asset.size">{{ asset.size | filesize }}</template>
        </div>
        <div class="w-1/12 p-1" v-if="showOptions" @click.stop>
            <AssetItemMenu
                v-bind:can-share="canShare"
                v-bind:can-delete="canDelete"
                v-bind:can-preview="canPreview && !isFolder"
                v-bind:can-replace="!isFolder"
                v-bind:can-move-item-up="canMoveItemUp"
                v-bind:parent-folder-id="activeFolder.parent"
                v-on:rename-asset="onRename"
                v-on:delete-asset="onDelete"
                v-on:preview-asset="onPreview"
                v-on:share-asset="onShare"
                v-on:move-asset="onMoveDirect(activeFolder.parent)"
            />
        </div>

        <div
            class="w-2/12 p-1 px-4 text-right whitespace-nowrap"
            v-if="showOpts && showSelectActionOption"
        >
            <div class="flex" v-if="isSelectableWithinRestrictions">

                <a
                    href="#"
                    @click.prevent="onToggleSelect"
                    class="w-full text-center text-gray-600 btn btn-sm"
                >Select</a>
            </div>
        </div>
    </div>-->
</template>

<script>
import item from './assets-explorer-collection-item-mixin.js'

const AssetItemThumb = () => import('@components/assets/asset-item-thumb')
const ProfileAvatarThumb = () => import('@components/profile/ProfileAvatarThumb')

export default {

  components: {
    AssetItemThumb,
    ProfileAvatarThumb
  },
  mixins: [item],

  data() {
    return {
      showOpts: false
    }
  },

  computed: {

    justifyClass() {
      let c = 'justify-start'
      if (this.showSelectActionOption) c = 'justify-between'

      return c
    },

    assetOwner: function () {
      if (this.asset.uploaded_by_name !== null) {
        return {
          name: this.asset.uploaded_by_name
        }
      }

      return null
    },

    hasThumbnail: function () {
      return (this.asset.thumbnail_tiny !== null)
    },

    rowClass: function () {
      let base = 'list-row-type-' + this.asset.type

      return {
        'active': this.isSelected || this.isFocused || this.isMultiselected,
        'list-row-draggable': this.canDrag,
        base
      }
    },

    thumbnail: function () {
      return this.asset.thumbnail_tiny
    },

    iconClass: function () {
      const type = this.asset.type
      const status = this.asset.status
      let icon = 'icon '

      switch (status) {
        case 'error':
          icon += 'warning circle red'
          break
        case 'pending':
          icon += 'spinner grey loading'
          break
        case 'processing':
          icon += 'spinner blue loading'
          break
        case 'ready':
          icon += 'file  '
          icon += (['image', 'video', 'folder'].includes(type) ? type : '')
          break
        case 'uploading':
          icon += ' spinner orange loading'
          break
        case 'disabled':
          icon += ' red lock'
          break
        default:
          icon += ' outline grey ' + type
          break
      }

      switch (type) {
        case 'folder':
          icon += ' blue '
          break
      }

      return icon
    }
  },

  mounted() {
    if (this.actionMode === 'update') {
      this.showOpts = true
    }
  },

  methods: {
    onMouseOver() {
      this.showOpts = true
    },

    onMouseLeave() {
      if (this.actionMode !== 'update') this.showOpts = false
    }
  }
}

</script>
